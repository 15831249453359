
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車】注目されているAMGモデルの特徴や通常のGクラスとの違いを徹底解説
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            メルセデスベンツの中古車市場で人気を集めるGクラスですが、中でも最上級グレードとして位置付けられているAMGモデルがおすすめです。通常のGクラスとGクラスAMGを比較し、それぞれの違いや特徴を把握しておきましょう。
            <div class="section-title">
              <span >AMGとは？通常のGクラスとGクラスAMGは何が違う？</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >海外の有名な自動車メーカーの一つである「メルセデスベンツ」。そんなメルセデスベンツの本格オフローダー「Gクラス」のハイブリッド版であり、エンジンや排気量、最高出力など、あらゆる面でグレードアップしたAMGモデルが人気です。</p>
                    <p >こちらでは、AMGとはなにか、通常のGクラスとGクラスAMGの違いについてご紹介いたします。</p>
                    <h3 class="heading-number" data-number="◆">AMGとは？</h3>  
                    <p >AMGは、レースエンジンを手掛けるエンジニアリング会社です。AMGがチューニング・カスタムしたメルセデス車を「AMGモデル」と呼んでいます。</p>
                    <p >もともと独立チューナーだったAMGですが、1999年にダイムラー・クライスラー（現ダイムラー）に吸収され、2014年からメルセデスAMGとしてメルセデスベンツのサブブランドとなりました。</p>
                    <h3 class="heading-number" data-number="◆">通常のGクラスとGクラスAMGの違い</h3>
                    <p >AMGモデルの代表的な特徴は、サーキット仕様であることです。レースエンジンを手掛けるエンジニアリング会社として培ってきた技術を存分に活かした強力なエンジンと、そのエンジン性能を受け止める強化ブレーキシステムやAMGスポーツサスペンションが搭載されています。サーキット走行でも耐えられるだけの性能の高さが魅力です。</p>
                    <p >また、走行性能だけでなく、上質な素材を使用した空間演出や専用デザインのエクステリアなどの仕様変更がされており、高級感溢れる内外装も特徴です。</p>
                    <p >Gクラスで最も高スペックな「G550」とAMGラインのGクラス「G63」を比べてみると、どちらも「ワンマン・ワンエンジン」の4.0L V8ツインターボエンジンを搭載していますが、最高出力を比べてみるとG550が422PSなのに対し、G63は585PSという高性能なスペックとなっています。</p>
                    <p >また、最大トルクについてもG550が62.2kgf・mなのに対し、G63は86.7kgf・mと高回転で、出力を向上しつつも安定した走りを追求したモデルだとわかります。</p>
                    <p >そして、通常のGクラスとG63を比べると、ボディサイズがわずかに大きく、車内空間が広いのも特徴です。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/mercedes-amg-model-car.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">販売台数の大半を占めるAMGモデル</h3>  
              <p >高級感と信頼の高い性能から選ばれ続けるメルセデスベンツですが、さらにチューニング・カスタムを重ね、安定したハイパフォーマンスを実現していることが、AMGモデルが売れている理由です。</p>
              <p >さらに高いスペックを実現し、高級感と快適性のバランスを突き詰めたAMGモデルは「SUVの形をしたスポーツカー」といわれるほどオフロード性能と高性能を両立したハイパフォーマンスモデルとして、人気を集めています。</p>
              <p >メルセデスベンツのAMGモデルの中古車をお探しでしたら、car shop MIXにご相談ください。全国各地のお客様への納車に対応しております。遠方のお客様も安心してご検討できるよう、車の状態につきましては細かくご説明させていただいております。現車を目の前にしてお電話でのご説明やご希望箇所の画像送信など、お客様が「自身の目で見た」感覚に近付けますようサポートさせていただきます。</p>
              <p >国産車・外車を問わず、MT車もAT車も、プレミアムカーを中心に取り揃えておりますので、ぜひ在庫一覧をご確認ください。</p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >Gクラスをはじめとした中古車探しはcar shop MIXにお任せ！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >Gクラスを選ぶなら、ハイパフォーマンスモデルであるAMGモデルがおすすめです。通常のGクラスに比べて価格は高い傾向にありますが、安定したパフォーマンスと高性能な走りを楽しめます。</p>
                    <p >また、ゆとりある車内スペースと素材にこだわった内装が生み出す空間が快適な乗り心地を提供してくれるでしょう。</p>
                    <p >AMGモデル「G63」など、Gクラスをはじめとした中古車をお探しでしたら、car shop MIXにお任せください。中古車の中でもレアな車種や最近見かけることが少なくなってきたマニュアル車など、市場にあまり出回らない車のご用意が可能です。</p>
                    <p >また、低走車や25年規制が解除されるような車を中心とした中古車探しも得意としております。</p>
                    <p >在庫一覧にほしい車両がない場合は、オークションを代行して探すこともできますので、中古車をお探しの方、特にレアな車種・マニュアル車をお探しの方は、car shop MIXにぜひ一度ご相談ください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="AMGモデル「G63」の中古車" src="@/assets/img/column-gtr-amg/amg-model-g63-used-car.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'AMGモデル「G63」などGクラスをはじめとした中古車探しはcar shop MIXにお任せください！'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv05.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
